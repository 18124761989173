import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { UseLifecycleExample } from './use-lifecycle.example.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "uselifecycle"
    }}>{`useLifecycle`}</h1>
    <p><inlineCode parentName="p">{`useLifecycle`}</inlineCode>{` is not one hook but three – `}<inlineCode parentName="p">{`useMount`}</inlineCode>{`, `}<inlineCode parentName="p">{`useUpdate`}</inlineCode>{` and
`}<inlineCode parentName="p">{`useUnmount`}</inlineCode>{`. They are hooks that can be used as replacements for lifecycle
hooks found on class components.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import { useMount, useUpdate, useUnmount } from '@fransvilhelm/hooks';

const Lifecycles = () => {
  useMount(() => console.log('Mounted'));
  useUpdate(() => console.log('Updated'));
  useUnmount(() => console.log('Unmounted'));

  return <p />;
};
`}</code></pre>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<UseLifecycleExample />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      UseLifecycleExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UseLifecycleExample mdxType="UseLifecycleExample" />
    </Playground>
    <h2 {...{
      "id": "parameters"
    }}>{`Parameters`}</h2>
    <h3 {...{
      "id": "usemount"
    }}><inlineCode parentName="h3">{`useMount`}</inlineCode></h3>
    <p><inlineCode parentName="p">{`useMount`}</inlineCode>{` accepts a callback function that will be called once directly after
initial render of the component.`}</p>
    <h3 {...{
      "id": "useupdate"
    }}><inlineCode parentName="h3">{`useUpdate`}</inlineCode></h3>
    <p><inlineCode parentName="p">{`useUpdate`}</inlineCode>{` accepts a callback function that will be called on every update
happening on the component (state or props updated). It's practically
`}<inlineCode parentName="p">{`useEffect`}</inlineCode>{` but without the initial call on first render.`}</p>
    <h3 {...{
      "id": "useunmount"
    }}><inlineCode parentName="h3">{`useUnmount`}</inlineCode></h3>
    <p><inlineCode parentName="p">{`useUnmount`}</inlineCode>{` accepts a callback function that will be called once in connection
to the component being unmounted.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      